<template>
  <div>
    <el-tabs type="border-card" :value="activeTab" style="width: 800px;margin-bottom: 10px">
      <el-tab-pane :label="$t('user.account.baseInfo')" name="first">
        <div v-loading="userContentLoading" style="padding: 10px 40px">
          <el-form
            ref="baseForm"
            class="form"
            label-width="70px"
            :model="user"
            :rules="BaseRule">
            <el-form-item :label="$t('user.type')">{{$t("user.types." + user.type)}}</el-form-item>
            <el-form-item :label="$t('user.company')">{{user.orgName}}</el-form-item>
            <el-form-item :label="$t('user.name')" prop="name">
              <el-input v-model="user.name" :placeholder="$t('common.pleaseEnter')"></el-input>
            </el-form-item>
            <el-form-item :label="$t('user.mail')" prop="email">
              <el-input v-model="user.email" :placeholder="$t('common.pleaseEnter')"></el-input>
            </el-form-item>
            <el-form-item :label="$t('user.phone')" prop="phone">
              <el-input v-model="user.phone" :placeholder="$t('common.pleaseEnter')" :maxlength="11"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="text" size="medium" :loading="userSubmitLoading" @click="handleUserSubmit">
                {{$t("common.save")}}
              </el-button>
            </el-form-item>
          </el-form>
        </div>
      </el-tab-pane>
      <el-tab-pane :label="$t('user.password')" name="second">
        <el-form
          ref="passwordForm"
          class="form"
          label-width="120px"
          :model="passwordForm"
          :rules="passwordRule"
          style="padding: 10px 40px">
          <el-form-item :label="$t('user.account.oldPassword')" prop="oldPassword">
            <el-input
              v-model="passwordForm.oldPassword"
              :placeholder="$t('common.pleaseEnter')"
              type="password"
              show-password></el-input>
          </el-form-item>
          <el-form-item :label="$t('user.account.newPassword')" prop="newPassword">
            <el-input
              v-model="passwordForm.newPassword"
              :placeholder="$t('common.pleaseEnter')"
              type="password"
              show-password></el-input>
          </el-form-item>
          <el-form-item :label="$t('user.account.confirm')" prop="checkPassword">
            <el-input
              v-model="passwordForm.checkPassword"
              :placeholder="$t('common.pleaseEnter')"
              type="password"
              show-password></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="text" size="medium" :loading="passwordSubmitLoading" @click="handlePasswordSubmit">
              {{$t("common.save")}}
            </el-button>
          </el-form-item>
        </el-form>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
  import qs from "qs";
  import validateUtil from "../../util/validateUtil";

  export default {
    data() {
      let validatePass = (rule, value, callback) => {
        if (value === "") {
          callback(new Error(this.$t("common.tip.notNull")));
        } else if (value !== this.passwordForm.newPassword) {
          callback(new Error(this.$t("user.account.tip.confirm")));
        } else {
          callback();
        }
      };
      return {
        userContentLoading: true,
        userSubmitLoading: true,
        passwordSubmitLoading: false,
        activeTab: "first",
        user: {
          typeName: "",
          name: "",
          email: "",
          phone: "",
        },
        passwordForm: {
          oldPassword: "",
          newPassword: "",
          checkPassword: "",
        },
        BaseRule: {
          email: [
            {type: "email", message: this.$t("user.tip.mail"), trigger: "blur"},
          ],
          phone: [
            {required: true, pattern: /^1[0-9]{10}$/, message: this.$t("user.tip.phone"), trigger: "blur"},
          ],
        },
        passwordRule: {
          oldPassword: [
            {required: true, message: this.$t("common.tip.notNull"), trigger: "blur"},
          ],
          newPassword: [
            {
              required: true,
              pattern: validateUtil.passwordRegx,
              message: this.$t("user.tip.password"),
              trigger: "blur",
            },
          ],
          checkPassword: [
            {required: true, validator: validatePass, trigger: "blur"},
          ],
        },
      };
    },
    mounted() {
      this.getUser();
      let activeTab = this.$route.query.activeTab;
      if (activeTab) {
        this.activeTab = activeTab;
      }
    },
    methods: {
      getUser() {
        this.$api.getData("account").then(res => {
          this.userContentLoading = false;
          this.userSubmitLoading = false;
          this.user = Object.assign(this.user, res.data);
        }).catch(() => {
          this.$message.error(this.$t("common.tip.getDataError"));
          this.userContentLoading = false;
        });
      },
      handleUserSubmit() {
        this.$refs.baseForm.validate((valid) => {
          if (valid) {
            this.userSubmitLoading = true;
            this.$axios.put("account", this.user).then(res => {
              this.userSubmitLoading = false;
              this.$message.success(this.$t("common.tip.saveSuccess"));
              this.$refs.baseForm.clearValidate();
            }).catch(error => {
              this.userSubmitLoading = false;
              this.$message.error(this.$t("common.tip.saveError") + "," + error.response.data.message);
            });
          }
        });
      },
      handlePasswordSubmit() {
        this.$refs.passwordForm.validate((valid) => {
          if (valid) {
            this.submitLoading = true;
            this.$axios.post("account/password", qs.stringify(this.passwordForm)).then(res => {
              this.userSubmitLoading = false;
              this.$message.success(this.$t("common.tip.saveSuccess"));
            }).catch(error => {
              this.userSubmitLoading = false;
              this.$message.error(this.$t("common.tip.saveError") + "," + error.response.data.message);
            });
          }
        });
      },
    },
  };
</script>

<style lang="scss" scoped>
.saveBtn {
  margin-top: 30px;
}
</style>
